'use strict';

angular
    .module('lmsApp')
    .controller(
        'CourseQuizzesController',
        function($scope, toastr, $stateParams, $translate, Course, Quiz, QuizResource, uuid4, Uuid, adaptedProgramList, $timeout, $q, usSpinnerService) {
            var _editor = {};
            $scope.course = null;
            $scope.topics = [];
            $scope.quizMap = {};
            $scope.quizImsccIdMap = {};
            $scope.courseQuizzes = [];
            $scope.courseQuizzesTotal; // Общее количество курсов, не учитывающее пагинацию
            $scope.quiz = {};
            $scope.edit = false;
            $scope.error = '';
            $scope.toDelete = null;
            $scope.quizSettings = true;
            $scope.quizQuestions = false;
            $scope.quizLink = '';
            $scope.weights = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
            $scope.courseQuizzesLesson = null;
            $scope.selectedOccupation = null;
            $scope.selectedLesson = null;
            $scope.checkQuizRequired = null;
            $scope.selectedAdaptedProgram = null;
            $scope.selectedVariation = null;
            $scope.selectedWeight = null;
            $scope.checkQuizOpt = [
                {
                    checked: true,
                    name: 'Да'
                },
                {
                    checked: false,
                    name: 'Нет'
                }
            ];
            $scope.pagination = {
                page: 1,
                total: 0,
                perPage: 20,
                showAll: true
            };
            $scope.sorting = {
                fields: ['id'],
                direction: 'asc'
            };
            $scope.adaptedProgramList = adaptedProgramList;
            $scope.onTestOccupationChange = function() {
                if ($scope.selectedOccupation) {
                    $scope.courseQuizzesLesson = $scope.selectedOccupation.lessons;
                }
                $scope.selectedLesson = null;
                $scope.checkQuizRequired = null;
                // $scope.selectedAdaptedProgram = null;
                // $scope.selectedVariation = null;
                // $scope.selectedWeight = null;
                $scope.load($stateParams.id);
            };
            $scope.onOccupationLessonChange = function() {
                $scope.checkQuizRequired = null;
                $scope.selectedAdaptedProgram = null;
                $scope.selectedVariation = null;
                $scope.selectedWeight = null;
                $scope.load($stateParams.id);
            };
            $scope.onOccupationRequiredChange = function() {
                $scope.load($stateParams.id);
            };
            $scope.onAdaptedProgramChange = function() {
                $scope.load($stateParams.id);
            };
            $scope.onVariationChange = function() {
                $scope.load($stateParams.id);
            };
            $scope.onWeightChange = function() {
                $scope.load($stateParams.id);
            };
            $scope.load = function(id) {
                // Промисы
                var proms = [];
                $timeout(
                    function() {
                        $scope.spinnerCurtain = true;
                        usSpinnerService.spin('spinnerOpenTasks');
                    },
                    0
                );
                $scope.courseId = id;
                var promCourse = Course.get(
                    {
                        id: id,
                        expand: 'topics'
                    },
                    function(result) {
                        $scope.course = result;
                        $scope.topics = $scope.course.topics;
                        if (!$scope.staticTopics) {
                          $scope.staticTopics = angular.copy($scope.topics);
                        }
                        $scope.variations = $scope.course.variations;
                    }
                ).$promise;
                proms.push(promCourse);

                function getSelectValue(selectModel) {
                    switch (selectModel) {
                        case 'Да':
                            return true;
                            break;
                        case 'Нет':
                            return false;
                            break;
                        default:
                            return null;
                    }
                }
                var _quizOptions = {
                    id: id,
                    sorting_fields: $scope.sorting.fields,
                    sorting_direction: $scope.sorting.direction,
                    checkQuizRequired: $scope.checkQuizRequired === null
                        ? null
                        : $scope.checkQuizRequired,
                    adaptedProgram: $scope.selectedAdaptedProgram
                        ? $scope.selectedAdaptedProgram.id
                        : null,
                    keyQuestion: getSelectValue($scope.keyQuestion),
                    taskForTopic: getSelectValue($scope.taskForTopic),
                    requiredByDefault: getSelectValue($scope.requiredByDefault),
                    markCanBeGiven: getSelectValue($scope.markCanBeGiven),
                    topicId: $scope.selectedOccupation
                        ? $scope.selectedOccupation.id
                        : null,
                    lessonId: $scope.selectedLesson
                        ? $scope.selectedLesson
                        : null,
                    variationId: $scope.selectedVariation
                        ? $scope.selectedVariation.id
                        : null,
                    taskForTopics: $scope.selectedTaskForTopics
                        ? $scope.selectedTaskForTopics
                        : null,
                    weight: $scope.selectedWeight ? $scope.selectedWeight : null
                }; // Создаем кэшированные параметры для получения общего количества квизов без учёта пагинации
                var _cachedQuizOptions = {};
                for (var key in _quizOptions) {
                    if (key !== 'per_page') {
                        _cachedQuizOptions[key] = _quizOptions[key];
                    }
                }
                if (!$scope.pagination.showAll) {
                    _quizOptions.page = $scope.pagination.page;
                    _quizOptions.per_page = $scope.pagination.perPage;
                }
                $q.all(proms).then(function() {
                    // Считаем общее количество квизов без влияния пагинации, но с влиянием фильтров
                    Course.getQuizzes(
                        _cachedQuizOptions,
                        function(result, headers) {
                            $scope.courseQuizzesTotal = result.length;
                        }
                    );
                    Course.getQuizzes(_quizOptions, function(result, headers) {
                        $scope.pagination.total = headers('X-Total-Count');
                        $scope.courseQuizzes = result;
                        angular.forEach($scope.courseQuizzes, function(value) {
                            $scope.quizMap[value.id] = value;
                            $scope.quizImsccIdMap[value.imsccId] = true;
                        });
                        angular.forEach($scope.topics, function(topic) {
                            angular.forEach(topic.lessons, function(lesson) {
                                angular.forEach(lesson.quizzes, function(quiz) {
                                    if ($scope.quizMap[quiz.id]) {
                                        $scope.quizMap[quiz.id].topic = topic;
                                        $scope.quizMap[quiz.id].lesson = {
                                            id: lesson.id
                                        };
                                        $scope.quizMap[
                                            quiz.id
                                        ].variation = quiz.variation;
                                    }
                                });
                            });
                        });
                        $scope.spinnerCurtain = false;
                        usSpinnerService.stop('spinnerOpenTasks');
                    });
                });
            }; /*$scope.setVariation = function (){
            var variant = { description: null };
            variant.name = $scope.quiz.variationTmp;
            $scope.variations.push(variant);
            $scope.quiz.variation = variant;
            $scope.variationTmp = null;
            $('#variantAddModal').modal('hide');
        };
        $scope.deleteVariation = function (name) {
            var delVariation = _.find($scope.variations, function(variation) { return variation.name === name})
            if(delVariation.id !==1) {
                Quiz.deleteQuisesVariation({ id: delVariation.id } , function (response) {
                    _.remove($scope.variations, function(variation) { return variation.name === name})
                    toastr.success('Удален');
                    $scope.quiz.variation = _.find($scope.variations, function(variation) { return variation.id === 1});
                    $('#variantDelModal').modal('hide');
                });
            } else {
                $('#variantDelModal').modal('hide');
            }
        };*/
            $scope.createQuiz = function() {
                $scope.quiz = {};
                $scope.quiz.imsccId = 'zzz' +
                    Uuid.getNewKey($scope.quizImsccIdMap) +
                    uuid4.generate();
                $scope.quiz.course = {
                    id: $scope.courseId
                };
                $scope.quiz.lesson = {};
                $scope.quiz.variation = null;
                $scope.quiz.required = false;
                $scope.quiz.shuffleAnswers = false;
                $scope.edit = false;
                $scope.error = null;
                $scope.quizSettings = true;
                $scope.quizQuestions = false;
                $scope.$broadcast('clearQuestionList');
                if (!_editor.instance) {
                    $scope.initEditor(_editor, 'quizDescriptionText', '');
                } else {
                    _editor.instance.setContent('');
                }
                $scope.quiz.s_category = null;
                $scope.quiz.weight = null;
                $('#saveQuizModal').modal('show');
            };
            $scope.confirmDelete = function(id) {
                $scope.toDelete = id;
                $('#deleteConfirmation').modal('show');
            };
            $scope.QuizResourceDeleteError = '';
            $scope.delete = function() {
                $scope.QuizResourceDeleteError = '';
                QuizResource.delete({
                    id: $scope.toDelete
                })
                    .$promise.then(function(res) {
                        for (var i = 0; i < $scope.courseQuizzes.length; i++) {
                            if (
                                $scope.courseQuizzes[i].id === $scope.toDelete
                            ) {
                                $scope.courseQuizzes.splice(i, 1);
                                break;
                            }
                        }
                        $scope.courseQuizzesTotal = $scope.courseQuizzes.length;
                        $scope.toDelete = null;
                        $('#deleteConfirmation').modal('hide');
                    })
                    .catch(function(res) {
                        // 404 - если квиз не найден
                        if (res.status === 404) {
                            $translate(
                                'lmsApp.course.quizzes.deleteModal.error404'
                            ).then(function(translatedValue) {
                                $scope.QuizResourceDeleteError = translatedValue;
                            });
                        } // 403 - если не достаточно прав доступа
                        if (res.status === 403) {
                            $translate(
                                'lmsApp.course.quizzes.deleteModal.error403'
                            ).then(function(translatedValue) {
                                $scope.QuizResourceDeleteError = translatedValue;
                            });
                        }
                    });
            };
            $scope.editQuiz = function(index) {
                $scope.error = '';
                $scope.quiz = $scope.courseQuizzes[index];
                $scope.quiz.weight = '' + $scope.courseQuizzes[index].weight;
                $scope.quiz.course = {
                    id: $scope.courseId
                };
                $scope.edit = true;
                $scope.error = null;
                $scope.$broadcast('quizIdChanged');
                if (!_editor.instance) {
                    $scope.initEditor(
                        _editor,
                        'quizDescriptionText',
                        $scope.quiz.description || ''
                    );
                } else {
                    _editor.instance.setContent($scope.quiz.description || '');
                }
                $('#saveQuizModal').modal('show');
            };
            $scope.save = function() {
                var topic = $scope.quiz.topic;
                var lesson = $scope.quiz.lesson;
                var course = $scope.quiz.course;
                var variation = $scope.quiz.variation;
                delete $scope.quiz.topic;
                delete $scope.quiz.variationTmp;
                if (_editor.instance) {
                    $scope.quiz.description = _editor.instance.getContent();
                }
                Quiz.update(
                    $scope.quiz,
                    function(response) {
                        if (!$scope.edit) {
                            var createdQuiz = response;
                            createdQuiz.course = course;
                            createdQuiz.topic = topic;
                            createdQuiz.lesson = lesson;
                            createdQuiz.variation = variation;
                            $scope.courseQuizzes.push(createdQuiz);
                            $scope.quizImsccIdMap[createdQuiz.imsccId] = true;
                        }
                        $scope.load($stateParams.id);
                        $('#saveQuizModal').modal('hide');
                        $scope.quiz.variation = null;
                        toastr.success('Тест сохранён');
                    },
                    function(response) {
                        $scope.error = response.headers('Failure');
                    }
                );
            };
            $scope.initEditor = function(editorInstance, selector, data) {
                tinyMCE.init({
                    selector: '#' + selector,
                    plugins: 'link code contextmenu table textcolor colorpicker lists advlist',
                    contextmenu: 'link image inserttable | cell row column deletetable lmsformula',
                    external_plugins: {
                        lmsimage: window.location.origin +
                            '/app/components/util/tinymce/plugins/lmsimage/lmsimage.js',
                        lmsformula: window.location.origin +
                            '/app/components/util/tinymce/plugins/lmsformula/lmsformula.js'
                    },
                    extended_valid_elements: 'a[onclick|ng-click|*]',
                    toolbar: 'nowrapbutton verticalAlign newdocument undo redo | bold italic underline strikethrough | forecolor backcolor | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | bullist numlist | link lmsimage code | table | lmsformula',
                    language: 'ru',
                    language_url: '/i18n/tinyMCE/ru.js',
                    course_id: $scope.courseId,
                    convert_urls: false,
                    formats: {
                        nowrap_format: {
                            inline: 'span',
                            styles: {
                                'white-space': 'nowrap',
                                display: 'inline-block',
                                'vertical-align': 'baseline'
                            },
                            attributes: {
                                class: 'nowrap'
                            }
                        },
                        verticalAlignMiddle: {
                            selector: 'img',
                            styles: {
                                'vertical-align': 'middle'
                            }
                        },
                        verticalAlignBaseline: {
                            selector: 'img',
                            styles: {
                                'vertical-align': 'baseline'
                            }
                        },
                        verticalAlignBottom: {
                            selector: 'img',
                            styles: {
                                'vertical-align': 'bottom'
                            }
                        }
                    },
                    setup: function(editor) {
                        editor.on('init', function(event) {
                            editorInstance.instance = editor;
                            editorInstance.instance.setContent(data);
                            if (!data) {
                                editor.execCommand('fontSize', false, '14pt');
                            }
                        });
                        editor.addButton('nowrapbutton', {
                            text: 'NoBr',
                            stateSelector: 'span.nowrap',
                            tooltip: 'Запретить перенос',
                            icon: false,
                            onclick: function(event) {
                                tinyMCE.activeEditor.formatter.toggle(
                                    'nowrap_format'
                                );
                            }
                        });
                        editor.addButton('verticalAlign', {
                            type: 'listbox',
                            text: 'VAlign',
                            icon: false,
                            onselect: function(e) {
                                var val = this.value();
                                switch (val) {
                                    case 1:
                                        tinyMCE.activeEditor.formatter.toggle(
                                            'verticalAlignMiddle'
                                        );
                                        break;
                                    case 2:
                                        tinyMCE.activeEditor.formatter.toggle(
                                            'verticalAlignBaseline'
                                        );
                                        break;
                                    case 3:
                                        tinyMCE.activeEditor.formatter.toggle(
                                            'verticalAlignBottom'
                                        );
                                        break;
                                }
                            },
                            values: [
                                {
                                    text: 'VAlign:middle',
                                    stateSelector: 'img',
                                    value: 1
                                },
                                {
                                    text: 'VAlign:baseline',
                                    stateSelector: 'img',
                                    value: 2
                                },
                                {
                                    text: 'VAlign:bottom',
                                    stateSelector: 'img',
                                    value: 3
                                }
                            ],
                            onPostRender: function() {
                                var self = this;
                                editor.on('click', function(e) {
                                    if (e.target.nodeName !== 'IMG') {
                                        self.value(0);
                                        return;
                                    }
                                    switch (e.target.style.verticalAlign) {
                                        case 'middle':
                                            self.value(1);
                                            break;
                                        case 'baseline':
                                            self.value(2);
                                            break;
                                        case 'bottom':
                                            self.value(3);
                                            break;
                                        default:
                                            break;
                                    }
                                });
                            }
                        });
                    },
                    content_style: '.nowrap { white-space:nowrap;background-color:#dbdbdb; }'
                });
            };
            $scope.copyToClipboard = function(selector) {
                var elem = angular.element(selector);
                elem[0].select();
                try {
                    document.execCommand('copy');
                } catch (err) {
                    console.log('unable to copy');
                }
            };
            $scope.sortQuizzesList = function(fields) {
                if (fields && fields.length > 0) {
                    if (fields[0] === $scope.sorting.fields[0]) {
                        $scope.sorting.direction = $scope.sorting.direction ===
                            'asc'
                            ? 'desc'
                            : 'asc';
                    } else {
                        $scope.sorting.fields = fields;
                        $scope.sorting.direction = 'asc';
                    }
                    $scope.load($stateParams.id);
                }
            };
            $scope.load($stateParams.id); // workaround for disabled tinyMCE input fields
            $(document).on('focusin', function(e) {
                if ($(e.target).closest('.mce-window').length) {
                    e.stopImmediatePropagation();
                }
            });
            $scope.$on('$stateChangeSuccess', function() {
                // kill editor instances, if any
                if (_editor.instance) {
                    _editor.instance.remove();
                    _editor.instance = null;
                } else if (tinyMCE.get('quizDescriptionText')) {
                    tinyMCE.get('quizDescriptionText').remove();
                }
                if (tinyMCE.get('quizQuestionText')) {
                    tinyMCE.get('quizQuestionText').remove();
                }
            });
            $scope.$watch(
                'quiz',
                function() {
                    if ($scope.quiz.imsccId && $scope.quiz.imsccId !== '') {
                        if ($scope.quiz.title && $scope.quiz.title !== '') {
                            markQuizLink(
                                $scope.quiz.title,
                                $scope.quiz.imsccId
                            );
                        } else {
                            markQuizLink('', $scope.quiz.imsccId);
                        }
                    }
                },
                true
            );
            function markQuizLink(title, imsccId) {
                $scope.quizLink = '<a onclick="LmsProxy.getInstance().openQuizz(event, \'' +
                    imsccId +
                    '\')" href="#">' +
                    title +
                    '</a>';
            }
            function getNewKey(obj) {
                var newKey = 1;
                for (var key in obj) {
                    if (
                        key.indexOf('zzz') === 0 &&
                        key.length > 10 &&
                        !isNaN(parseInt(key.substring(3, 7))) &&
                        parseInt(key.substring(3, 7)) >= newKey
                    ) {
                        newKey = parseInt(key.substring(3, 7)) + 1;
                    }
                }
                newKey = newKey.toString().lpad('0', 4);
                return newKey;
            }
            String.prototype.lpad = function(padString, length) {
                var str = this;
                while (str.length < length) {
                    str = padString + str;
                }
                return str;
            };
            $scope.changePerPageCount = function(number) {
                $scope.pagination.perPage = number;
                $scope.pagination.page = 1;
                $scope.pagination.showAll = false;
                $scope.load($stateParams.id);
            };
            $scope.changePerPageToAll = function() {
                $scope.pagination.showAll = true;
                $scope.load($stateParams.id);
            };
        }
    );
