'use strict';

angular.module('lmsApp')
    .factory('AuthServerProvider', function loginService($http, localStorageService, ipCookie) {
        return {
            login: function(credentials) {
                var data = 'username=' + credentials.username + '&password=' + credentials.password;
                return $http.post('api/authenticate', data, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json'
                    }
                }).success(function (response) {
                    localStorageService.set('token', response);
                    ipCookie('LMS_AUTH', response.data.token, {path: '/', expires: 7});
                    ipCookie('LMS_AUTH', response.data.token, {path: '/', domain: '.mob-edu.ru', expires: 7});
                    return response;
                });
            },
            logout: function() {
                //Stateless API : No server logout
                localStorageService.clearAll();
                ipCookie.remove('LMS_AUTH', {path: '/'});
                ipCookie.remove('LMS_AUTH', {path: '/', domain: '.mob-edu.ru' });
            },
            getToken: function () {
                return localStorageService.get('token');
            },
            hasValidToken: function () {
                var token = this.getToken();
                return token && token.expires && token.expires > new Date().getTime();
            }
        };
    });
