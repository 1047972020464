'use strict';

angular.module('lmsApp').controller('TerritoryDetailController', [
  '$scope',
  '$q',
  '$stateParams',
  'Territory',
  'Region',
  'Locations',
  'School',
  'Users',
  function($scope, $q, $stateParams, Territory, Region, Locations, School, Users) {
    var _roleTerritoryCoordinator = 'ROLE_LOCATION_COORDINATOR',
      _roleTerritoryAdmin = 'ROLE_LOCAL_ADMIN';

    $scope.coordOptions = {
      page: 1,
      per_page: 10,
      sorting_fields: ['id'],
      sorting_direction: 'asc'
    };

    $scope.currentList = [];
    $scope.territory = {};
    $scope.excludedIds = [];
    $scope.getData;
    $scope.deleteList = [];
    var loadOptions;

    $scope.load = function(id) {
      Region.get(
        {
          id: id
        },
        function(result) {
          $scope.territory = result;
          $scope.territory.admins === null ? ($scope.territory.admins = []) : $scope.territory;
        }
      );
      $scope.getData();
    };

    $scope.showAddUserDialog = function(role) {
      if (role === _roleTerritoryCoordinator) {
        $scope.currentRoles = 'coordinators';
      } else {
        $scope.currentRoles = 'admins';
      }
      $scope.error = {};
      //promise load data before open modal window
      $scope.getCurrentData().then(function(response) {
        $('#addUserModal').modal('show');
      });
    };

    $scope.showDeleteUserDialog = function(role) {
      if (role === _roleTerritoryCoordinator) {
        $scope.currentRoles = 'coordinators';
        $scope.deleteList = $scope.territory.coordinators;
      } else {
        $scope.currentRoles = 'admins';
        $scope.deleteList = $scope.territory.admins;
      }
      $('#deleteUserModal').modal('show');
    };

    $scope.addUser = function() {
      var addUserFn = $scope.currentRoles === 'admins' ? 'addAdmin' : 'addCoordinator',
        userParams = {
          firstId: $scope.territory.id,
          secondId: $scope.newUser.id
        };

      Locations[addUserFn](userParams, function() {
        $('#addUserModal').modal('hide');
        $scope.newUser = void 0;
        $scope.load($stateParams.id);
      }).$promise.catch(function(response) {
        // Обработка ошибки 409
        if (response.status === 409) {
          $scope.error.conflict = 'Региональный координатор не может быть территориальным координатором. Добавьте другого пользователя.';
        } else {
          console.error(response);
        }
      });
    };
    $scope.deleteUser = function() {
      var deleteUserFn = $scope.currentRoles === 'admins' ? 'deleteAdmin' : 'deleteCoordinator',
        deleteUserIdParam = $scope.currentRoles === 'admins' ? 'admin_id' : 'coordinator_id',
        userParams = {
          id: $scope.territory.id
        };
      userParams[deleteUserIdParam] = $scope.removedUserId;
      Locations[deleteUserFn](userParams, function() {
        $('#deleteUserModal').modal('hide');
        $scope.load($stateParams.id);
      });
    };
    function getUserById(id) {
      id = parseInt(id, 10);
      for (var i in $scope.currentList.items) {
        if ($scope.currentList.items[i].id && $scope.currentList.items[i].id === id) {
          return $scope.currentList.items[i];
        }
      }
      return null;
    }
    function removeAddedCoordinatorsFromList(arrUserInTable, arrUser) {
      if (arrUserInTable && arrUserInTable.length === 0) {
        return;
      }
      for (var z in arrUserInTable) {
        remove(arrUser, arrUserInTable[z]);
      }
    }
    function remove(arr, item) {
      for (var i = arr.length; i--; ) {
        if (arr[i].id === item.id) {
          arr.splice(i, 1);
        }
      }
    }
    $scope.getCurrentData = function(loadOptions) {
      updateExcludedIds();
      return $q(function(resolve, reject) {
        loadOptions = loadOptions ? loadOptions : $scope.coordOptions;
        loadOptions.role = $scope.currentRoles === 'admins'
          ? _roleTerritoryAdmin
          : _roleTerritoryCoordinator;
        Users.getAll(
          loadOptions,
          function(result, headers) {
            $scope.currentList = {
              items: result,
              total: headers('X-Total-Count')
            };
            removeAddedCoordinatorsFromList(
              $scope.territory[$scope.currentRoles],
              $scope.currentList.items
            );
            resolve(result);
          },
          function(err) {
            reject(err);
          }
        );
      });
    };
    $scope.getData = function(params) {
      var loadOptions = params || {};
      loadOptions = loadOptions ? loadOptions : $scope.coordOptions;
      loadOptions.role = $scope.currentRoles === 'admins'
        ? _roleTerritoryAdmin
        : _roleTerritoryCoordinator;
      Users.getAll(loadOptions, function(result, headers) {
        $scope.currentList = {
          items: result,
          total: headers('X-Total-Count')
        };
      });
    };
    function updateExcludedIds() {
      $scope.excludedIds = [];
      $scope.territory[$scope.currentRoles].forEach(function(currentRole) {
        $scope.excludedIds.push(currentRole.id);
      });
      if ($scope.excludedIds.length === 0) {
        $scope.excludedIds.push(-999);
      }
    }
    $scope.load($stateParams.id);
  }
]);
