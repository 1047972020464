(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('lmsApp')
        .constant('VERSION', "1.4.1")
        .constant('GIT', {
	"message": "feat: удалил кросдоменные куки; сделал назначение куки при загрузке приложения",
	"commit": "be05ec9b66c68d890780479322d7f23b2f95f39c",
	"branch": "integration",
	"date": "2022-12-13T09:44:47.000Z"
})
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('ENV', "prod")
        .constant('API', {
	"newAdmin": "/adm",
	"course": "/course"
})
        .constant('innopolis', undefined)
;
})();
