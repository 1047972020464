'use strict';

angular
  .module('lmsApp')
  .controller(
    'LearningclassController',
    function($scope, Learningclass, Classes, School, SchoolNew, UserGroup) {
      $scope.learningclasses = [];
      $scope.schools = [];
      $scope.userGroups = [];
      $scope.currentYear;
      $scope.learningclass = {};
      $scope.years = []; // годы обучения в фильтре на общей странице и на странице "Просмотр" (объекты)
      $scope.yearValues = []; // годы обучения в модальном окне создания/редактирования учебного класса (только числовые значения)
      $scope.selectedYear = {}; // выбранный год, по дефолту - текущий учебный год
      $scope.error = '';
      $scope.hiddenButtons
      $scope.nameUserEdit = false;
      $scope.schoolsOptions = {
        page: 1,
        per_page: 5,
        sorting_fields: ['id'],
        sorting_direction: 'desc'
      };
      $scope.pagination = {
        page: 1,
        total: 0,
        perPage: 20
      };
      $scope.sorting = {
        fields: ['id'],
        direction: 'desc'
      };
      $scope.ugOptions = {
        page: 1,
        per_page: 5,
        sorting_fields: ['id'],
        sorting_direction: 'desc'
      };

      // Получить с бэкенда список годов
      Learningclass.getYears().$promise.then(function(response) {
        $scope.years = response;
        $scope.yearValues = response.filter(function(year) {
          return year.value >= getСurrentYear();
        }).map(function(year) {
          return year.value;
        });
        return $scope.years.filter(function(year) {
           return year.value === getСurrentYear();
        });
      }).then(function(selectedYear) {
        // Выбрать по дефолту текущий учебный год и загрузить первичную информацию на страницу
        $scope.selectedYear = selectedYear[0];
        $scope.loadAll();
      });

      // Показать по дефолту текущий учебный год
      function getСurrentYear() {
        var date = new Date(),
          month = date.getMonth(),
          year = date.getFullYear();
        if (month >= 5) {
          //текущий учебный год - начиная с июня, так как вряд ли в июне будут создаваться учебные классы за подходящий к концу год
          return $scope.currentYear = year;
        } else {
          return $scope.currentYear = year - 1;
        }
      }

      // $scope.incrementYear = function() {
      //   if ($scope.learningclass.year === undefined) {
      //     $scope.learningclass.year = parseInt($scope.currentYear);
      //   }
      //   $scope.learningclass.year++;
      // }

      // $scope.decrementYear = function() {
      //   if ($scope.learningclass.year === undefined) {
      //     $scope.learningclass.year = parseInt($scope.currentYear);
      //   }
      //   $scope.learningclass.year--;
      //   if ( $scope.learningclass.year < $scope.currentYear) {
      //     $scope.learningclass.year = parseInt($scope.currentYear);
      //   }
      // }

      $scope.loadAll = function() {
        Classes.getAll(
          {
            page: $scope.pagination.page,
            per_page: $scope.pagination.perPage,
            sorting_fields: $scope.sorting.fields,
            sorting_direction: $scope.sorting.direction,
            year: $scope.selectedYear.value
          },
          function(result, headers) {
            $scope.learningclasses = result;
            $scope.pagination.total = headers('X-Total-Count');
          }
        );
        $scope.getSchools();
      };
      $scope.changePerPageCount = function(number) {
        $scope.pagination.perPage = number;
        $scope.pagination.page = 1;
        $scope.loadAll();
      }; //     $scope.loadFreeUserGroupsAndCurrentUserGroup = function (loadOptions, currentUserGroupId) { //         loadOptions = (loadOptions) ? loadOptions : $scope.ugOptions; //         loadOptions.currentUserGroupId = -1; //         if (currentUserGroupId) { //             loadOptions.currentUserGroupId = currentUserGroupId; //         } else if ($scope.learningclass && $scope.learningclass.userGroup && $scope.learningclass.userGroup.id) { //             loadOptions.currentUserGroupId = $scope.learningclass.userGroup.id; //         } //         UserGroup.getFreeUserGroups(loadOptions, function (result, headers) { //             $scope.userGroups = { //                 'items': result, //                 'total': headers('X-Total-Count'), //             }; //         }); //     };
      $scope.create = function() {
        if (
          $scope.learningclass.school !== void 0 &&
          $scope.learningclass.school !== null &&
          $scope.learningclass.school.id === null
        ) {
          $scope.learningclass.school = null;
        }
        if (
          $scope.learningclass.userGroup !== void 0 &&
          $scope.learningclass.userGroup !== null &&
          $scope.learningclass.userGroup.id === null
        ) {
          $scope.learningclass.userGroup = null;
        }

        // Переопределяем значение поля year в поле learningClass, потому что бэкенд ждёт только год, и преобразует в объект с value, current и name самостоятельно, именно при обновлении существующего класса
        if ($scope.learningclass.id) {
          $scope.learningclass.year = $scope.learningclass.year.value;
        }
        //
        Learningclass.update(
          $scope.learningclass,
          function() {
            $scope.loadAll();
            $('#saveLearningclassModal').modal('hide');
            $scope.clear();
          },
          function(response) {
            $scope.error = response.headers('Failure');
          }
        );
      };
      $scope.update = function(id) {
        Learningclass.get({ id: id }, function(result) {
          $scope.learningclass = result;

          //             $scope.loadFreeUserGroupsAndCurrentUserGroup($scope.ugOptions, $scope.learningclass.userGroup === null ? -1 : $scope.learningclass.userGroup.id);
          $('#saveLearningclassModal').modal('show');
        });
      };
      $scope.delete = function(id) {
        Learningclass.get(
          {
            id: id
          },
          function(result) {
            $scope.learningclass = result;
            $('#deleteLearningclassConfirmation').modal('show');
          }
        );
      };
        $scope.confirmDelete = function (id) {
            Learningclass.delete({id: id}, function () {
                    //                 $scope.loadFreeUserGroupsAndCurrentUserGroup();
                    $('#deleteLearningclassConfirmation').modal('hide');
                    $scope.clear();
                    $scope.loadAll();
                },

                function (response) {
                    $scope.learningClassError = response.headers('Failure');
                });
      };
      $scope.clear = function() {
        //         $scope.loadFreeUserGroupsAndCurrentUserGroup();
        $scope.learningclass = {
          name: null,
          parallel: null,
          letter: null,
          id: null,
          year: $scope.currentYear
        };
        $scope.learningClassError = null;
        $scope.editForm.$setPristine();
        $scope.editForm.$setUntouched();
        $scope.error = '';
      };
      $scope.getSchools = function(loadOptions) {
        loadOptions = loadOptions ? loadOptions : $scope.schoolsOptions;
        SchoolNew.getAll(loadOptions, function(result, headers) {
          $scope.schools = { items: result, total: headers('X-Total-Count') };
        });
      };
      $scope.changeName = function(e) {
        if ($scope.learningclass && $scope.learningclass.name && $scope.learningclass.name !== '') {
          $scope.nameUserEdit = true;
        } else {
          $scope.nameUserEdit = false;
        }
      };
      $scope.changeLetterParallel = function(e) {
        if ($scope.learningclass && !$scope.nameUserEdit) {
          $scope.learningclass.name = '';
          $scope.learningclass.name += $scope.learningclass.parallel
            ? $scope.learningclass.parallel
            : '';
          $scope.learningclass.name += $scope.learningclass.letter
            ? $scope.learningclass.letter
            : '';
        }
      };
      $scope.sortLearningClasses = function(field) {
        if (field && field !== '') {
          if ($scope.sorting.fields[0] === field) {
            $scope.sorting.direction = $scope.sorting.direction === 'asc' ? 'desc' : 'asc';
          } else {
            $scope.sorting.fields[0] = field;
            $scope.sorting.direction = 'asc';
          }
        }
        $scope.loadAll();
      };
      // $scope.loadAll();
       //     $scope.loadFreeUserGroupsAndCurrentUserGroup();
    }
  );
