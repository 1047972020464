'use strict';

angular
  .module('lmsApp')
  .directive(
    'quizQuestions',
    function($stateParams, toastr, $translate, Quiz, $anchorScroll, $location, uuid4, StaticPageHelper, Question) {
      return {
        restrict: 'E',
        templateUrl: 'app/entities/course/course-quizQuestions.html',
        link: function(scope) {
          var _text_editor = {};
          var _hint_editor = {};
          scope.oldQuestion = null;
          scope.questions = {};
          scope.question = {};
          scope.questionTypes = [
            {
              id: 0,
              name: 'multiple_choice_question',
              label: 'Единичный выбор'
            },
            {
              id: 4,
              name: 'multiple_answers_question',
              label: 'Множественный выбор'
            },
            {
              id: 3,
              name: 'fill_in_multiple_blanks_question',
              label: 'Вписывание'
            },
            {
              id: 5,
              name: 'multiple_dropdowns_question',
              label: 'Выпадающий список'
            },
            {
              id: 6,
              name: 'matching_question',
              label: 'Установление соответствий'
            },
            {
              id: 7,
              name: 'numerical_question',
              label: 'Числовой ответ'
            },
            {
              id: 2,
              name: 'short_answer_question',
              label: 'Заполните пустое поле'
            },
            {
              id: 1,
              name: 'true_false_question',
              label: 'Да или Нет'
            }
          ];
          scope.questionInProgress = false;
          scope.hasQuestions = false;
          scope.responsesInProgress = false;
          scope.allQuestions = [];
          scope.lastQuestionId = 1;
          scope.newQuestion = newQuestion;
          scope.moveQuestionUp = moveQuestionUp;
          scope.moveQuestionDown = moveQuestionDown;
          scope.saveQuestion = saveQuestion;
          scope.editQuestion = editQuestion;
          scope.removeQuestion = removeQuestion;
          scope.clearQuestion = clearQuestion;
          scope.switchResponses = switchResponses;
          scope.isQuestionTypeSupported = isQuestionTypeSupported;
          scope.validate = validate;
          scope.initQuestionEditor = initQuestionEditor;
          scope.changeQuestionType = changeQuestionType;
          scope.$on('quizIdChanged', init);
          scope.$on('clearQuestionList', function() {
            scope.questions = {};
            clearQuestion();
          });
          scope.$on('tinyMCE ready', function() {
            $('input[name="title"]').focus();
          });
          function init(event, args) {
            $location.hash('quizQuestionText');
            Quiz.getQuestionSection(
              { id: scope.quiz.id },
              function(data) {
                scope.questions = data.questions === undefined
                  ? (data.questions = [])
                  : data.questions;
                scope.hasQuestions = Object.keys(scope.questions).length > 0;
                scope.questionInProgress = false;
                toList();
              },
              function(error) {
                showError(error);
              }
            );
          }
          function compare(a, b) {
            if (a.number < b.number) return -1;
            if (a.number > b.number) return 1;
            return 0;
          }
          function toList() {
            scope.allQuestions = [];
            angular.forEach(Object.keys(scope.questions), function(value, key) {
              if (scope.lastQuestionId < scope.questions[value].number) {
                scope.lastQuestionId = scope.questions[value].number;
              }
              scope.allQuestions.push(scope.questions[value]);
            });
            scope.allQuestions.sort(compare);
          }
          function newQuestion() {
            var uuid = 'zzz' + getNewKey(scope.questions) + uuid4.generate();
            scope.question = {
              imsccId: uuid,
              text: '',
              hint: '',
              responses: {},
              number: Object.keys(scope.questions).length + 1
            };
            scope.questionInProgress = true;
            setTimeout(
              function() {
                scope.initQuestionEditor(_text_editor, 'quizQuestionText', '');
                scope.initQuestionEditor(_hint_editor, 'quizQuestionHint', '');
              },
              200
            );
          }
          function moveQuestionUp(id) {
            Question.updateQuestionUp(
              {
                id: id
              },
              null,
              function(data) {
                init();
              }
            );
          }
          function moveQuestionDown(id) {
            Question.updateQuestionDown(
              {
                id: id
              },
              null,
              function(data) {
                init();
              }
            );
          }
          function editQuestion(imsccId) {
            clearQuestion();
            scope.questionInProgress = true;
            scope.question = scope.questions[imsccId];
            scope.oldQuestion = angular.copy(scope.question);
            setTimeout(
              function() {
                scope.initQuestionEditor(_text_editor, 'quizQuestionText', scope.question.text);
                scope.initQuestionEditor(_hint_editor, 'quizQuestionHint', scope.question.hint);
              },
              200
            );
            $anchorScroll();
          }
          function removeQuestion(imsccId) {
            if (
              !confirm(
                $translate.instant('lmsApp.course.quizzes.createModal.confirmQuestionRemove')
              )
            ) {
              return;
            }
            if (scope.question.imsccId && scope.question.imsccId === imsccId) {
              // to prevent errors, let's clear question edit form
              clearQuestion();
            }
            Quiz.deleteQuestion(
              {
                id: scope.quiz.id,
                imsccId: imsccId
              },
              function() {
                delete scope.questions[imsccId];
                toList();
              },
              function(error) {
                showError(error);
              }
            );
          }
          function saveQuestion() {
            if (_text_editor.instance) {
              scope.question.text = _text_editor.instance.getContent();
            }
            if (_hint_editor.instance) {
              scope.question.hint = _hint_editor.instance.getContent();
            }
            var validationError = validate(scope.question);
            if (validationError) {
              scope.error = 'Ошибка валидации: ' + validationError;
              return;
            }
            scope.questionInProgress = false;
            Quiz.updateQuestion(
              {
                id: scope.quiz.id
              },
              scope.question,
              function() {
                init();
                scope.oldQuestion = null;
                clearQuestion();
                toastr.success('Вопрос сохранён');
              },
              function(error) {
                showError(error);
              }
            );
          }
          function clearQuestion() {
            if (scope.oldQuestion !== void 0 && scope.oldQuestion !== null) {
              scope.questions[scope.question.imsccId] = angular.copy(scope.oldQuestion);
              scope.oldQuestion = null;
            }
            scope.questionInProgress = false;
            scope.questionForm.$setPristine();
            scope.questionForm.$setUntouched();
            scope.error = null;
            if (_text_editor.instance) {
              _text_editor.instance.remove();
              _text_editor.instance = null;
            }
            if (_hint_editor.instance) {
              _hint_editor.instance.remove();
              _hint_editor.instance = null;
            }
          }
          function showError(error) {
            scope.error = 'Произошла ошибка: ';
            scope.error += error.headers('Failure') || error.statusText + '. ' + error.data.message;
          }
          function switchResponses() {
            scope.responsesInProgress = !scope.responsesInProgress;
            // if (scope.responsesInProgress === false) { //     scope.responsesInProgress = true; // } else { //     scope.responsesInProgress = false; // }
          }
          function isQuestionTypeSupported(type) {
            return [
              'multiple_choice_question',
              'multiple_answers_question',
              'fill_in_multiple_blanks_question',
              'multiple_dropdowns_question',
              'matching_question',
              'numerical_question',
              'short_answer_question',
              'true_false_question'
            ].indexOf(type) > -1;
          }
          function validate(question) {
            // Объявление и инициализация переменной type - тип вопрсоа и responses - массив ответов
            var type = question.type.name;
            var responses = question.responses;
            switch (type) {
              // Проверка вопроса типа - "Несколько вариантов выбора"
              case 'multiple_choice_question':
                if (objectLength(responses) < 2) {
                  return 'Слишком мало вариантов ответа';
                }
                break; // Проверка вопроса типа - "Да или нет"
              case 'true_false_question':
                if (objectLength(responses) != 2) {
                  return 'Количество вариантов ответов должно равняться двум';
                }
                break; // Проверка вопроса типа - "Множественный выбор"
              case 'multiple_answers_question':
                if (objectLength(responses) <= 0) {
                  return 'Слишком мало вариантов ответа';
                }
                break; // Проверка вопроса типа - "Числовой ответ"
              case 'numerical_question':
                var deleteLower = false;
                var deleteUpper = false;
                if (objectLength(responses) < 1) {
                  return 'Не введено точное значение';
                } else {
                  if (
                    question.responses.correct.text === void 0 ||
                    question.responses.correct.text === null
                  ) {
                    return 'Не введено точное значение';
                  }
                  if (question.responses.upper !== void 0 && question.responses.upper !== null) {
                    //Проверка при редактировании, что не пришел объект upper с null
                    if (
                      question.responses.upper.text === null ||
                      question.responses.upper.text === void 0
                    ) {
                      deleteUpper = true;
                    } else if (+question.responses.upper.text < +question.responses.correct.text) {
                      return 'Верхняя граница не может быть меньше точного ответа';
                    }
                  }
                  if (question.responses.lower !== void 0 && question.responses.lower !== null) {
                    //Проверка при редактировании, что не пришел объект lower с null
                    if (
                      question.responses.lower.text === null ||
                      question.responses.lower.text === void 0
                    ) {
                      deleteLower = true;
                    } else if (+question.responses.lower.text > +question.responses.correct.text) {
                      return 'Нижняя граница не может быть больше точного ответа';
                    }
                  }
                  if (deleteLower === true) {
                    delete question.responses.lower;
                  }
                  if (deleteUpper === true) {
                    delete question.responses.upper;
                  }
                }
                break;
              // Проверка вопроса типа - "Заполните пустое поле"
              case 'short_answer_question':
                if (objectLength(responses) < 1) {
                  // Проверка на количество добавленных вариантов ответа
                  return 'Количество вариантов ответов должно быть не меньше единицы';
                }
                break; // Проверка вопроса типа - "Вписывание"
              case 'fill_in_multiple_blanks_question':
                if (objectLength(responses) < 1) {
                  // Проверка на количество добавленных вариантов ответа
                  return 'Количество вариантов ответов должно быть не меньше единицы';
                }
                for (var resp in responses) {
                  var itemsArray = arrayFromMap(responses[resp].items);
                  for (var i = 0; i < itemsArray.length; i++) {
                    var item = itemsArray[i];
                    if (!item.text) {
                      return 'Пустое поле для варианта ответа ' + responses[resp].text;
                    }
                  }
                  if (itemsArray.length < 1) {
                    return 'Слишком мало опций ответа для пункта ' + responses[resp].text;
                  }
                }
                for (var resp in responses) {
                  if (question.text.indexOf('[' + responses[resp].text + ']') == -1) {
                    return 'Не хватает метки в тексте вопроса для пункта ' + responses[resp].text;
                  }
                }
                break; // Проверка вопроса типа - "Выпадающий список"
              case 'multiple_dropdowns_question':
                if (objectLength(responses) < 1) {
                  // Проверка на количество добавленных вариантов ответа
                  return 'Отсутствуют варианты ответа';
                }
                for (var resp in responses) {
                  var itemsArray = arrayFromMap(responses[resp].items);
                  for (var i = 0; i < itemsArray.length; i++) {
                    var item = itemsArray[i];
                    if (!item.text) {
                      return 'Пустое поле для варианта ответа ' + responses[resp].text;
                    }
                  }
                  if (itemsArray.length < 2) {
                    return 'Слишком мало опций ответа для пункта ' + responses[resp].text;
                  }
                }
                for (var resp in responses) {
                  if (question.text.indexOf('[' + responses[resp].text + ']') == -1) {
                    return 'Не хватает метки в тексте вопроса для пункта ' + responses[resp].text;
                  }
                }
                break; // Проверка вопроса типа - "Установление соответствий"
              case 'matching_question':
                if (objectLength(responses) < 2) {
                  return 'Слишком мало вариантов ответа';
                }
                var questionOptionsCorrectPointers = {}, answerOptions = [];
                for (resp in responses) {
                  if (responses[resp].correctImsccId !== void 0 && responses[resp].correctImsccId) {
                    questionOptionsCorrectPointers[responses[resp].correctImsccId] = true;
                  }
                }
                for (resp in responses) {
                  if (
                    !responses[resp].correctImsccId && questionOptionsCorrectPointers[resp] === true
                  ) {
                    answerOptions.push(resp);
                  }
                }
                if (answerOptions.length < objectLength(questionOptionsCorrectPointers)) {
                  return 'Слишком мало опций ответа для пунктов вопросов';
                }
                break;
              default:
                return 'Необходимо выбрать типа вопроса';
            } // Проверка на пустой вариант ответа
            if (checkResponsesFieldsForEmptiness(responses, 'text')) {
              return 'Пустой ответ не возможен';
            }
          }
          function arrayFromMap(obj) {
            var arr = [];
            for (var e in obj) {
              if (obj.hasOwnProperty(e)) {
                arr.push(obj[e]);
              }
            }
            return arr;
          }
          function checkResponsesFieldsForEmptiness(responses, fieldName) {
            if (!responses || !fieldName || fieldName === '') return false;
            for (var key in responses) {
              if (
                !responses[key][fieldName] ||
                responses[key][fieldName] === '' ||
                /^\s+$/.test(responses[key][fieldName])
              )
                return true;
            }
            return false;
          }
          function objectLength(obj) {
            var element_count = 0;
            for (var e in obj) {
              if (obj.hasOwnProperty(e)) {
                element_count++;
              }
            }
            return element_count;
          }
          function getNewKey(obj) {
            var newKey = 1;
            for (var key in obj) {
              if (
                key.indexOf('zzz') === 0 &&
                key.length > 10 &&
                !isNaN(parseInt(key.substring(3, 7))) &&
                parseInt(key.substring(3, 7)) >= newKey
              ) {
                newKey = parseInt(key.substring(3, 7)) + 1;
              }
            }
            newKey = newKey.toString().lpad('0', 4);
            return newKey;
          }
          function initQuestionEditor(questionEditor, questionElId, questionText) {
            if (questionEditor === void 0 || questionElId === void 0 || questionElId === '') {
              return false;
            }
            StaticPageHelper.initEditor(
              questionEditor,
              questionElId,
              questionText ? questionText : '',
              scope.courseId
            );
          }
          function changeQuestionType(question, oldQuestionType) {
            if (
              confirm(
                'Внимание! При изменении типа вопроса, все варианты ответов вопроса будут удалены. Продолжить?'
              )
            ) {
              question.responses = {};
              scope.responsesInProgress = false;
            } else {
              question.type = oldQuestionType;
            }
          }
          String.prototype.lpad = function(padString, length) {
            var str = this;
            while (str.length < length) {
              str = padString + str;
            }
            return str;
          };
        }
      };
    }
  );
