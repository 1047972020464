'use strict';

angular.module('lmsApp').controller('CourseOpenTasksController', [
    '$scope',
    '$translate',
    'toastr',
    '$stateParams',
    'Course',
    'Principal',
    'uuid4',
    'Uuid',
    'Assignment',
    'AssignmentResource',
    'adaptedProgramList',
    '$timeout',
    'usSpinnerService',
    '$q',
    function(
        $scope,
        $translate,
        toastr,
        $stateParams,
        Course,
        Principal,
        uuid4,
        Uuid,
        Assignment,
        AssignmentResource,
        adaptedProgramList,
        $timeout,
        usSpinnerService,
        $q
    ) {
        var _editor = {};
        var sameKeyQuizError = 'Another assignment is already selected as key question for this lesson: ';
        var sameTopicQuizError = 'Another assignment is already selected as topic task: ';
        $scope.sameQuizKeyError = false;
        $scope.sameTopicQuizError = false;
        $scope.courseOpenTasks = [];
        $scope.courseOpenTasksTotal; // Общее количество курсов, не учитывающее пагинацию
        $scope.parrentPath = '';
        $scope.openTaskSettings = true;
        $scope.unpublished = Principal.isInRole('OP_MANAGE_COURSES');
        $scope.showStudentList = Principal.isInRole('OP_VIEW_COURSE_SECTIONS');
        $scope.openTask = {};
        $scope.taskLink = '';
        $scope.flag = false;
        $scope.weights = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        $scope.courseAssignmentsLesson = null;
        $scope.keyQuestion = null;
        $scope.taskForTopic = null;
        $scope.requiredByDefault = null;
        $scope.markCanBeGiven = null;
        $scope.selectedAdaptedProgram = null;
        $scope.selectedOccupation = null;
        $scope.selectedLesson = null;
        $scope.selectedVariation = null;
        $scope.selectedTaskForTopics = null;
        $scope.selectedWeight = null;
        $scope.assignmentsMap = [];
        $scope.pagination = {
            page: 1,
            total: 0,
            perPage: 20,
            showAll: true
        };
        $scope.sorting = {
            fields: ['title'],
            direction: 'asc'
        };
        $scope.adaptedProgramList = adaptedProgramList;
        $scope.onTestOccupationChange = function() {
            if ($scope.selectedOccupation) {
                $scope.courseAssignmentsLesson = $scope.selectedOccupation.lessons;
            }
            $scope.selectedLesson = null;
            $scope.checkAssignmentRequired = null;
            // $scope.selectedAdaptedProgram = null;
            // $scope.selectedVariation = null;
            // $scope.selectedWeight = null;
            $scope.load($stateParams.id);
        };
        $scope.onOccupationLessonChange = function() {
            $scope.checkAssignmentRequired = null;
            $scope.selectedAdaptedProgram = null;
            $scope.selectedVariation = null;
            $scope.selectedWeight = null;
            $scope.load($stateParams.id);
        };
        $scope.onOccupationRequiredChange = function() {
            $scope.load($stateParams.id);
        };
        $scope.onAdaptedProgramChange = function() {
            $scope.load($stateParams.id);
        };
        $scope.onVariationChange = function() {
            $scope.load($stateParams.id);
        };
        $scope.onWeightChange = function() {
            $scope.load($stateParams.id);
        };
        $scope.load = function(id) {
            // Промисы
            var proms = [];
            $timeout(
                function() {
                    $scope.spinnerCurtain = true;
                    usSpinnerService.spin('spinnerOpenTasks');
                },
                0
            );
            $scope.courseId = id;
            var promCourse = Course.get({
              id: id,
              expand: 'topics'
            },
              function (result) {
                $scope.course = result;
                // Адаптивный или нет курс
                $scope.courseIsAdapted = !!result.adapted;
                $scope.topics = $scope.course.topics;
                if (!$scope.staticTopics) {
                  $scope.staticTopics = angular.copy($scope.topics);
                }
                $scope.variations = $scope.course.variations;
              }
            ).$promise;
            proms.push(promCourse);

            function getSelectValue(selectModel) {
                switch (selectModel) {
                    case 'Да':
                        return true;
                        break;
                    case 'Нет':
                        return false;
                        break;
                    default:
                        return null;
                }
            }
            var _otOptions = {
                id: id,
                unpublished: $scope.unpublished,
                sorting_fields: $scope.sorting.fields,
                sorting_direction: $scope.sorting.direction,
                adaptedProgram: $scope.selectedAdaptedProgram
                    ? $scope.selectedAdaptedProgram.id
                    : null,
                keyQuestion: getSelectValue($scope.keyQuestion),
                taskForTopic: getSelectValue($scope.taskForTopic),
                requiredByDefault: getSelectValue($scope.requiredByDefault),
                markCanBeGiven: getSelectValue($scope.markCanBeGiven),
                topicId: $scope.selectedOccupation
                    ? $scope.selectedOccupation.id
                    : null,
                lessonId: $scope.selectedLesson ? $scope.selectedLesson : null,
                variationId: $scope.selectedVariation
                    ? $scope.selectedVariation.id
                    : null,
                taskForTopics: $scope.selectedTaskForTopics
                    ? $scope.selectedTaskForTopics
                    : null,
                weight: $scope.selectedWeight ? $scope.selectedWeight : null
            }; // Создаем кэшированные параметры для получения общего количества квизов без учёта пагинации
            var _cachedOtOptions = {};
            for (var key in _otOptions) {
                if (key !== 'per_page') {
                    _cachedOtOptions[key] = _otOptions[key];
                }
            }
            if (!$scope.pagination.showAll) {
                _otOptions.page = $scope.pagination.page;
                _otOptions.per_page = $scope.pagination.perPage;
            }
            $q.all(proms).then(function() {
                // Считаем общее количество квизов без влияния пагинации, но с влиянием фильтров
                Course.getOpenTasks(
                    _cachedOtOptions,
                    function(openTasks, headers) {
                        $scope.courseOpenTasksTotal = openTasks.length;
                    }
                );
                Course.getOpenTasks(_otOptions, function(openTasks, headers) {
                    $scope.pagination.total = headers('X-Total-Count');
                    $scope.courseOpenTasks = openTasks;
                    angular.forEach($scope.courseAssignments, function(value) {
                        $scope.assignmentsMap[value.id] = value;
                        $scope.assignmentsImsccIdMap[value.imsccId] = true;
                    });
                    angular.forEach($scope.topics, function(topic) {
                        angular.forEach(topic.lessons, function(lesson) {
                            angular.forEach(lesson.assignments, function(quiz) {
                                if ($scope.assignmentsMap[quiz.id]) {
                                    $scope.assignmentsMap[
                                        quiz.id
                                    ].topic = topic;
                                    $scope.assignmentsMap[quiz.id].lesson = {
                                        id: lesson.id
                                    };
                                    $scope.assignmentsMap[
                                        quiz.id
                                    ].variation = quiz.variation;
                                }
                            });
                        });
                    });
                    $scope.spinnerCurtain = false;
                    usSpinnerService.stop('spinnerOpenTasks');
                });
            });
        };
        $scope.copyToClipboard = function(selector) {
            var elem = angular.element(selector);
            elem[0].select();
            try {
                document.execCommand('copy');
            } catch (err) {
                console.log('unable to copy');
            }
        }; /*$scope.setVariation = function (){
                var variant = { description: null };
                variant.name = $scope.variationTmp;
                $scope.variations.push(variant);
                $scope.openTask.variation = variant;
                $scope.variationTmp = null;
                $('#variantAddModal').modal('hide');
            };
            $scope.deleteVariation = function (name) {
                var delVariation = _.find($scope.variations, function(variation) { return variation.name === name})
                if(delVariation.id !==1) {
                    Assignment.deleteOpenTaskVariation({ id: delVariation.id } , function (response) {
                        _.remove($scope.variations, function(variation) { return variation.name === name})
                        toastr.success('Удален');
                        $scope.openTask.variation = _.find($scope.variations, function(variation) { return variation.id === 1});
                        $('#variantDelModal').modal('hide');
                    });
                } else {
                    $('#variantDelModal').modal('hide');
                }
            }*/
        $scope.category = function(category) {
            $scope.openTask.addaptedQuestion = (category === 'C_DEFAULT') ? $scope.openTask.addaptedQuestion : false;
        };
        $scope.cloneForm = false;
        $scope.createOpenTask = function() {
            $scope.newForm = true;
            $scope.editedForm = false;
            $scope.cloneForm = false;

            $scope.creating = true;
            $scope.sameQuizKeyError = false;
            $scope.sameTopicQuizError = false;
            $scope.openTask = {};
            $scope.openTask.addaptedQuestion = false;
            $scope.openTask.imsccParentId = null;
            $scope.topic = null;
            $scope.lesson = null;
            $scope.variation = null;
            $scope.openTask.variation = $scope.variations &&
                $scope.variations.length
                ? $scope.variations[0]
                : null;
            $scope.variationTmp = null;
            $scope.openTask.imsccId = Uuid.getNewKey($scope.quizImsccIdMap) +
                uuid4.generate();
            $scope.edit = false;
            $scope.openTask.markCanBeGiven = false;
            if (!_editor.instance) {
                $scope.initEditor(_editor, 'openTaskText', '');
            } else {
                _editor.instance.setContent('');
            }
            $scope.flag = false;
            $scope.error = null;
            $scope.openTask.s_category = null;
            $scope.openTask.weight = null;
            $('#saveOpenTaskModal').modal({
                backdrop: 'static',
                keyboard: false
            });
        };
        $scope.editOpenTask = function(index) {
            $scope.newForm = false;
            $scope.editedForm = true;
            $scope.cloneForm = false;

            var n;
            $scope.edit = true;
            $scope.sameQuizKeyError = false;
            $scope.sameTopicQuizError = false;
            $scope.openTask = $scope.courseOpenTasks[index];
            $scope.openTask.weight = '' + $scope.courseOpenTasks[index].weight;
            if ($scope.openTask.weight === 'undefined') {
                $scope.openTask.weight = '1';
            }
            $scope.openTask.course = {
                id: $scope.courseId
            };
            $scope.openTask.openTaskForTopic = !!$scope.openTask.path;
            if ($scope.openTask.openTaskForTopic) {
                n = $scope.openTask.path.lastIndexOf('/');
                $scope.parrentPath = $scope.openTask.path.substring(0, n + 1);
                $scope.openTask.openTaskFileName = $scope.openTask.path.substring(
                    n + 1
                );
                // $scope.openTask.requiredByDefault = true; // $scope.openTask.markCanBeGiven = true; // $scope.openTask.keyQuestion = false;
                // MASH-632
                angular.forEach($scope.topics, function(topic) {
                    angular.forEach(topic.assignments, function(assignment) {
                        if (assignment.id === $scope.openTask.id) {
                            $scope.openTask.topic = topic;
                            $scope.openTask.variation = assignment.variation;
                        }
                    });
                });
            } else {
                angular.forEach($scope.topics, function(topic) {
                    angular.forEach(topic.lessons, function(lesson) {
                        angular.forEach(
                            lesson.assignments,
                            function(assignment) {
                                if (assignment.id === $scope.openTask.id) {
                                    $scope.openTask.topic = topic;
                                    $scope.openTask.lesson = lesson;
                                    $scope.openTask.variation = assignment.variation;
                                }
                            }
                        );
                    });
                });
            }
            if ($scope.openTask.keyQuestion === true) {
                $scope.openTask.requiredByDefault = true;
                $scope.openTask.markCanBeGiven = true;
                $scope.openTask.openTaskForTopic = false;
            }
            $scope.edit = true;
            if (!_editor.instance) {
                $scope.initEditor(
                    _editor,
                    'openTaskText',
                    $scope.openTask.text || ''
                );
            } else {
                _editor.instance.setContent($scope.openTask.text || '');
            }
            $scope.flag = false;
            $('#saveOpenTaskModal').modal({
                backdrop: 'static',
                keyboard: false
            });
        };

        $scope.cloneOpenTask = function(index) {
            $scope.newForm = false;
            $scope.editedForm = false;
            $scope.cloneForm = true;

            var n;
            $scope.edit = true;
            $scope.sameQuizKeyError = false;
            $scope.sameTopicQuizError = false;
            $scope.openTask = angular.copy($scope.courseOpenTasks[index]);
            $scope.openTask.weight = '' + $scope.courseOpenTasks[index].weight;
            if ($scope.openTask.weight === 'undefined') {
                $scope.openTask.weight = '1';
            }
            $scope.openTask.course = {
                id: $scope.courseId
            };
            $scope.openTask.openTaskForTopic = !!$scope.openTask.path;
            if ($scope.openTask.openTaskForTopic) {
                n = $scope.openTask.path.lastIndexOf('/');
                $scope.parrentPath = $scope.openTask.path.substring(0, n + 1);
                $scope.openTask.openTaskFileName = $scope.openTask.path.substring(
                    n + 1
                );
                angular.forEach($scope.topics, function(topic) {
                    angular.forEach(topic.assignments, function(assignment) {
                        if (assignment.id === $scope.openTask.id) {
                            $scope.openTask.topic = topic;
                            $scope.openTask.variation = assignment.variation;
                        }
                    });
                });
            } else {
                angular.forEach($scope.topics, function(topic) {
                    angular.forEach(topic.lessons, function(lesson) {
                        angular.forEach(
                            lesson.assignments,
                            function(assignment) {
                                if (assignment.id === $scope.openTask.id) {
                                    $scope.openTask.topic = topic;
                                    $scope.openTask.lesson = lesson;
                                    $scope.openTask.variation = assignment.variation;
                                }
                            }
                        );
                    });
                });
            }
            if ($scope.openTask.keyQuestion === true) {
                $scope.openTask.requiredByDefault = true;
                $scope.openTask.markCanBeGiven = true;
                $scope.openTask.openTaskForTopic = false;
            }
            $scope.edit = true;
            if (!_editor.instance) {
                $scope.initEditor(
                    _editor,
                    'openTaskText',
                    $scope.openTask.text || ''
                );
            } else {
                _editor.instance.setContent($scope.openTask.text || '');
            }
            $scope.flag = false;
            $('#saveOpenTaskModal').modal({
                backdrop: 'static',
                keyboard: false
            });

            // Создаём новый imsccId;
            var oldImsccId = $scope.openTask.imsccId;
            $scope.openTask.imsccId = Uuid.getNewKey($scope.quizImsccIdMap) + uuid4.generate();
            $scope.openTask.imsccParentId = null;
            // Категорию ставим на выбрать
            $scope.openTask.s_category = '';
            $scope.openTask.id = null;
            // Создать все адаптации
            $scope.openTask.addaptedQuestion = false;
        };

        $scope.initEditor = function(editorInstance, selector, data) {
            tinyMCE.init({
                selector: '#' + selector,
                plugins: 'link code contextmenu table textcolor colorpicker lists advlist',
                contextmenu: 'link image inserttable | cell row column deletetable lmsformula',
                external_plugins: {
                    lmsimage: window.location.origin +
                        '/app/components/util/tinymce/plugins/lmsimage/lmsimage.js',
                    lmsformula: window.location.origin +
                        '/app/components/util/tinymce/plugins/lmsformula/lmsformula.js'
                },
                extended_valid_elements: 'a[onclick|ng-click|*]',
                toolbar: 'nowrapbutton verticalAlign newdocument undo redo | bold italic underline strikethrough | forecolor backcolor | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | bullist numlist | link lmsimage code | table | lmsformula',
                language: 'ru',
                language_url: '/i18n/tinyMCE/ru.js',
                course_id: $scope.courseId,
                convert_urls: false,
                formats: {
                    nowrap_format: {
                        inline: 'span',
                        styles: {
                            'white-space': 'nowrap',
                            display: 'inline-block',
                            'vertical-align': 'baseline'
                        },
                        attributes: {
                            class: 'nowrap'
                        }
                    },
                    verticalAlignMiddle: {
                        selector: 'img',
                        styles: {
                            'vertical-align': 'middle'
                        }
                    },
                    verticalAlignBaseline: {
                        selector: 'img',
                        styles: {
                            'vertical-align': 'baseline'
                        }
                    },
                    verticalAlignBottom: {
                        selector: 'img',
                        styles: {
                            'vertical-align': 'bottom'
                        }
                    }
                },
                setup: function(editor) {
                    editor.on('init', function(event) {
                        editorInstance.instance = editor;
                        editorInstance.instance.setContent(data);
                        if (!data) {
                            editor.execCommand('fontSize', false, '14pt');
                        }
                    });
                    editor.addButton('nowrapbutton', {
                        text: 'NoBr',
                        stateSelector: 'span.nowrap',
                        tooltip: 'Запретить перенос',
                        icon: false,
                        onclick: function(event) {
                            tinyMCE.activeEditor.formatter.toggle(
                                'nowrap_format'
                            );
                        }
                    });
                    editor.addButton('verticalAlign', {
                        type: 'listbox',
                        text: 'VAlign',
                        icon: false,
                        onselect: function(e) {
                            var val = this.value();
                            switch (val) {
                                case 1:
                                    tinyMCE.activeEditor.formatter.toggle(
                                        'verticalAlignMiddle'
                                    );
                                    break;
                                case 2:
                                    tinyMCE.activeEditor.formatter.toggle(
                                        'verticalAlignBaseline'
                                    );
                                    break;
                                case 3:
                                    tinyMCE.activeEditor.formatter.toggle(
                                        'verticalAlignBottom'
                                    );
                                    break;
                            }
                        },
                        values: [
                            {
                                text: 'VAlign:middle',
                                stateSelector: 'img',
                                value: 1
                            },
                            {
                                text: 'VAlign:baseline',
                                stateSelector: 'img',
                                value: 2
                            },
                            {
                                text: 'VAlign:bottom',
                                stateSelector: 'img',
                                value: 3
                            }
                        ],
                        onPostRender: function() {
                            var self = this;
                            editor.on('click', function(e) {
                                if (e.target.nodeName !== 'IMG') {
                                    self.value(0);
                                    return;
                                }
                                switch (e.target.style.verticalAlign) {
                                    case 'middle':
                                        self.value(1);
                                        break;
                                    case 'baseline':
                                        self.value(2);
                                        break;
                                    case 'bottom':
                                        self.value(3);
                                        break;
                                    default:
                                        break;
                                }
                            });
                        }
                    });
                },
                content_style: '.nowrap { white-space:nowrap;background-color:#dbdbdb; }'
            });
        };
        $scope.load($stateParams.id); // workaround for disabled tinyMCE input fields
        $(document).on('focusin', function(e) {
            if ($(e.target).closest('.mce-window').length) {
                e.stopImmediatePropagation();
            }
        });
        $scope.$on('$stateChangeSuccess', function() {
            // kill editor instances, if any
            if (_editor.instance) {
                _editor.instance.remove();
                _editor.instance = null;
            } else if (tinyMCE.get('openTaskText')) {
                tinyMCE.get('openTaskText').remove();
            }
            if (tinyMCE.get('openTaskText')) {
                tinyMCE.get('openTaskText').remove();
            }
        });
        function getNewKey(obj) {
            var newKey = 1;
            for (var key in obj) {
                if (
                    key.indexOf('zzz') === 0 &&
                    key.length > 10 &&
                    !isNaN(parseInt(key.substring(3, 7))) &&
                    parseInt(key.substring(3, 7)) >= newKey
                ) {
                    newKey = parseInt(key.substring(3, 7)) + 1;
                }
            }
            newKey = newKey.toString().lpad('0', 4);
            return newKey;
        }
        String.prototype.lpad = function(padString, length) {
            var str = this;
            while (str.length < length) {
                str = padString + str;
            }
            return str;
        };
        $scope.save = function() {
            $scope.openTask.course = $scope.course;
            if (_editor.instance) {
                $scope.openTask.text = _editor.instance.getContent();
            }
            if (!$scope.openTask.openTaskForTopic) {
                $scope.openTask.path = null;
            } else {
                if ($scope.openTask.openTaskFileName === null) {
                    $scope.openTask.openTaskFileName = 'none';
                }
                $scope.openTask.path = $scope.openTask.openTaskFileName;
            }
            $scope.flag = true;
            $scope.error = false;

            // Если дефолтная ставим парент по айди imsccId
            if ($scope.openTask.s_category == 'C_DEFAULT') {
              $scope.openTask.imsccParentId = null;
            }

            Assignment.updateOpenTask(
                $scope.openTask,
                function(response) {
                    $scope.openTask = '';
                    $('#saveOpenTaskModal').modal('hide');
                    toastr.success('ЗОО сохранено');
                    $scope.load($stateParams.id);
                },
                function(error) {
                    if (
                        error.headers('Failure') &&
                        error.headers('Failure').indexOf(sameKeyQuizError) !==
                            -1
                    ) {
                        $scope.sameQuizKeyError = true;
                    }
                    if (
                        error.headers('Failure') &&
                        error.headers('Failure').indexOf(sameTopicQuizError) !==
                            -1
                    ) {
                        $scope.sameTopicQuizError = true;
                    }
                    var headers = error.headers();
                    if (headers.error_code) {
                        //$scope.error = 'Задание для данного занятия сущесвует';
                        $scope.error = headers.error_msg;
                    }
                    $('.modal').scrollTop(0);
                    $scope.flag = false;
                    $scope.cloneForm = false;
                }
            );
        };
        $scope.confirmDelete = function(id, imsccId) {
            // Проверяем ссылаются ли на него другие задание как на дефолтную
            $scope.spinnerCurtain = true;
            usSpinnerService.spin('spinnerOpenTasks');
            Course.openTasksWithParentId({ imsccId: imsccId }, function(arrayAssignments, headers) {
               usSpinnerService.stop('spinnerOpenTasks');
               $scope.spinnerCurtain = false;
               if (arrayAssignments.length !== 0) {
                  return alert('Задание невозможно удалить, так как на него ссылаются задания с адаптивными программами! ' + arrayAssignments.length + ' штук.');
               }
               // Если нету удаляем
               $scope.toDelete = id;
               $('#deleteConfirmation').modal({
                  backdrop: 'static',
                  keyboard: false
               });
            });
            return;
        };
        $scope.ResourceDeleteError = '';
        $scope.delete = function() {
            $scope.ResourceDeleteError = '';
            AssignmentResource.delete({
                id: $scope.toDelete
            })
                .$promise.then(function(res) {
                    $scope.toDelete = null;
                    $('#deleteConfirmation').modal('hide');
                    $scope.load($stateParams.id);
                })
                .catch(function(res) {
                    if (res.status === 404) {
                        $translate(
                            'lmsApp.course.openTasks.errorModal.error404'
                        ).then(function(translatedValue) {
                            $scope.ResourceDeleteError = translatedValue;
                        });
                    }
                    if (res.status === 403) {
                        $translate(
                            'lmsApp.course.openTasks.errorModal.error403'
                        ).then(function(translatedValue) {
                            $scope.ResourceDeleteError = translatedValue;
                        });
                    }
                });
        };
        $scope.changeOpenTaskForTopic = function(openTaskForTopic) {
            if (openTaskForTopic === true) {
                $scope.openTask.requiredByDefault = true;
                $scope.openTask.markCanBeGiven = true;
                $scope.openTask.keyQuestion = false;
            }
        };
        $scope.changeKeyTask = function(keyTask) {
            if (keyTask === true) {
                $scope.openTask.openTaskForTopic = false;
                $scope.openTask.requiredByDefault = true;
                $scope.openTask.markCanBeGiven = true;
            }
        };
        $scope.changePerPageCount = function(number) {
            $scope.pagination.perPage = number;
            $scope.pagination.page = 1;
            $scope.pagination.showAll = false;
            $scope.load($stateParams.id);
        };
        $scope.changePerPageToAll = function() {
            $scope.pagination.showAll = true;
            $scope.load($stateParams.id);
        };
        $scope.sortOpenTaskList = function(fields) {
            if (fields && fields.length > 0) {
                if (fields[0] === $scope.sorting.fields[0]) {
                    $scope.sorting.direction = $scope.sorting.direction ===
                        'asc'
                        ? 'desc'
                        : 'asc';
                } else {
                    $scope.sorting.fields = fields;
                    $scope.sorting.direction = 'asc';
                }
                $scope.load($stateParams.id);
            }
        };
        $scope.$watch(
            'openTask',
            function() {
                if ($scope.openTask.imsccId && $scope.openTask.imsccId !== '') {
                    if ($scope.openTask.title && $scope.openTask.title !== '') {
                        markOpenTaskLink(
                            $scope.openTask.title,
                            $scope.openTask.imsccId
                        );
                    } else {
                        markOpenTaskLink('', $scope.openTask.imsccId);
                    }
                }
                testRequiredByDefault();
                testImsccParentId();
            },
            true
        );
        function markOpenTaskLink(title, imsccId) {
            $scope.taskLink = '<a onclick="LmsProxy.getInstance().openTask(event, \'' +
                imsccId +
                '\')" href="#">' +
                title +
                '</a>';
        }
        function testRequiredByDefault() {
            var requiredByDefault = $scope.openTask.requiredByDefault; // Убрать возможность создания зоо с одним выбранным "обязательно для выполнения"
            if (requiredByDefault === true) {
                if (
                    !$scope.openTask.openTaskForTopic &&
                    !$scope.openTask.keyQuestion &&
                    !$scope.openTask.markCanBeGiven
                ) {
                    $timeout(function() {
                        $scope.editForm.requiredByDefault.$invalid = true;
                    });
                    return;
                }
            }
            $scope.editForm.requiredByDefault.$invalid = false;
        }


        function testImsccParentId() {
          // Убрать возможность создания с одинаковыми imsccParentId и imsccId
          if (!$scope.editForm.imsccParentId) {
            return;
          }
          var imsccParentId = $scope.openTask.imsccParentId;
          var imsccId = $scope.openTask.imsccId;
          if ($scope.editForm.imsccParentId.$error.required) {
            return;
          }
          if (imsccParentId === imsccId) {
            $scope.editForm.imsccParentId.$setValidity('uniq', false);
          } else {
            $scope.editForm.imsccParentId.$setValidity('uniq', true);
          }
       }
    }
]);
