/**
 * Created by ablokhin on 11.08.2015.
 */
'use strict';
/**
 * Директива, для перевода системных ролей на странице "Пользователи"
 */
angular.module('lmsApp')
    .directive('translateRole', function ($translate) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                attrs.$observe('translateRole', function (roleName) {
                    if ($translate.instant('lmsApp.cruduser.roles.' + roleName).indexOf(roleName) === -1) {
                        return element.text($translate.instant('lmsApp.cruduser.roles.' + roleName));
                    } else {
                        return element.text(roleName);
                    }
                });
            }
        };
    }
);