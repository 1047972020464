'use strict';

angular.module('lmsApp').controller('RegionDetailController', ['$scope', '$q', '$stateParams', 'Region', 'Locations', 'Territory', 'Users',
    function($scope, $q, $stateParams, Region, Locations, Territory, Users) {

        var _roleRegionCoordinator = 'ROLE_LOCATION_COORDINATOR',
            _roleRegionAdmin = 'ROLE_LOCAL_ADMIN';

        $scope.coordOptions = {
            'page': 1,
            'per_page': 10,
            'sorting_fields': ['id'],
            'sorting_direction': 'asc'
        };

        $scope.currentList = [];
        $scope.region = {};
        $scope.excludedIds = [];
        $scope.getData;
        $scope.deleteList = [];
        var loadOptions;

        $scope.load = function(id) {
            Region.get({
                id: id
            }, function(result) {
                $scope.region = result;
                $scope.region.admins === null
                    ? $scope.region.admins = []
                    : $scope.region
            });
        };

        $scope.getData = function(loadOptions) {
            $scope.getCurrentData(loadOptions).then(function(result) {
                $scope.currentList = result;
            });
        }

        $scope.showAddUserDialog = function(role) {

            if (role === _roleRegionCoordinator) {
                $scope.currentRoles = 'coordinators'
            } else {
                $scope.currentRoles = 'admins'
            }
            //promise load data before open modal window
            $scope.getCurrentData(loadOptions).then(function(result) {
                $scope.currentList = result;
                $('#addUserModal').modal('show');
            });
        };

        $scope.showDeleteUserDialog = function(role) {
            if (role === _roleRegionCoordinator) {
                $scope.currentRoles = 'coordinators'
                $scope.deleteList = $scope.region.coordinators;
            } else {
                $scope.currentRoles = 'admins'
                $scope.deleteList = $scope.region.admins
            }
            $('#deleteUserModal').modal('show');
        };

        $scope.addUser = function() {
            var addUserFn = ($scope.currentRoles === 'admins') ? 'addAdmin' : 'addCoordinator',
                userParams = {
                    firstId: $scope.region.id,
                    secondId: $scope.newUser.id
                };

            Locations[addUserFn](userParams, function() {
                $('#addUserModal').modal('hide');
                $scope.newUser = void 0;
                $scope.load($stateParams.id);
            });
        };

        $scope.deleteUser = function() {
            var deleteUserFn = ($scope.currentRoles === 'admins') ? 'deleteAdmin' : 'deleteCoordinator',
                deleteUserIdParam = ($scope.currentRoles === 'admins') ? 'admin_id' : 'coordinator_id',
                userParams = {
                    id: $scope.region.id
                };

            userParams[deleteUserIdParam] = $scope.removedUserId;

            Locations[deleteUserFn](userParams, function() {
                $('#deleteUserModal').modal('hide');
                $scope.load($stateParams.id);
            });
        };

        function getUserById(id) {
            id = parseInt(id, 10);
            for (var i in $scope.currentList.items) {
                if ($scope.currentList.items[i].id && $scope.currentList.items[i].id === id) {
                    return $scope.currentList.items[i];
                }
            }
            return null;
        }

        function removeAddedCoordinatorsFromList(arrUserInTable, arrUser) {
            if (arrUserInTable && arrUserInTable.length === 0) {
                return;
            }

            for (var z in arrUserInTable) {
                remove(arrUser, arrUserInTable[z]);
            }
        }

        function remove(arr, item) {
            for (var i = arr.length; i--;) {
                if (arr[i].id === item.id) {
                    arr.splice(i, 1);
                }
            }
        }

        $scope.getCurrentData = function(loadOptions) {
            updateExcludedIds();
            return $q(function(resolve, reject) {
                loadOptions = (loadOptions)
                    ? loadOptions
                    : $scope.coordOptions;
                loadOptions.role = $scope.currentRoles === 'admins'
                    ? _roleRegionAdmin
                    : _roleRegionCoordinator;
                if ($scope.excludedIds && $scope.excludedIds.length > 0) {
                    loadOptions.excluded_ids = $scope.excludedIds.join(',');
                }
                Users.getAll(loadOptions, function(result, headers) {
                    var loadData = {
                        'items': result,
                        'total': headers('X-Total-Count')
                    };
                    removeAddedCoordinatorsFromList($scope.region[$scope.currentRoles], loadData.items);
                    //return promise resolve
                    resolve(loadData);
                });
            })
        };

        function updateExcludedIds() {
            $scope.excludedIds = [];
            $scope.region[$scope.currentRoles].forEach(function(currentRole) {
                $scope.excludedIds.push(currentRole.id)
            })
            if ($scope.excludedIds.length === 0) {
                $scope.excludedIds.push(-999);
            }
        }

        $scope.load($stateParams.id);
    }
]);
